import { MutableRefObject, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Tabs } from '@skatteetaten/frontend-components/Tabs';
import { TabItem } from '@skatteetaten/frontend-components/Tabs/TabItem';
import { Panel } from '@skatteetaten/ds-content';
import { ArrowDownSVGpath, CalculatorSVGpath } from '@skatteetaten/ds-icons';
import { Heading } from '@skatteetaten/ds-typography';
import { Popover } from '@skatteetaten/ds-overlays';
import { Icon } from '../components/DS/Icon';
import { Button } from '../components/DS/Button';

import style from './Forside.module.scss';
import { FormattedHTMLMessageWithFallbackToUgly } from '../components/FormattedHTMLMessage';
import { Skattemeldingside } from '../components/SkattemeldingSide';
import { useDispatcher } from '../../utils/reduxHooks';
import {
  gaaTilEktefelleSide,
  gaaTilSkattemeldingSide,
} from '../../actions/navigasjon';
import { EKTEFELLESIDE, SKATTEMELDINGSIDE } from '../../reducers/navigasjon';
import { getGjeldendeSide } from '../../reducers';
import { getErSivilstandGift } from '../../reducers/skatteplikt';
import { useBeregnetSkattData } from '../../hooks/useAktivSkvis';
import { scrollToPosition } from '../../utils/scroll';
import { getPos } from '../../utils/scrollUtils';
import { useInntektsaar } from '../../hooks/useInntektsaar';
import { useAnalytics } from '../../analytics/analytics.hooks';
import { useKildeSkattPaaLoenn } from '../components/kildeskattePaaLoenn/useKildeskattPaaLoenn';
import { formaterTall } from '../components/util/FormatertTall';
import classnames from 'classnames';

export const Forside = () => {
  const intl = useIntl();
  const analytics = useAnalytics();

  const skatteberegningRef = useRef(null);
  const [isSkatteberegningOpen, setIsSkatteberegningOpen] = useState(false);

  const gjeldendeSide = useSelector(getGjeldendeSide);
  const erGift = useSelector(getErSivilstandGift);
  const { beregnKplModus } = useKildeSkattPaaLoenn();
  const dispatchTilSkattemelding = useDispatcher(gaaTilSkattemeldingSide);
  const dispatchTilEktefelle = useDispatcher(gaaTilEktefelleSide);

  const toggleIsSkatteberegningOpen = () =>
    setIsSkatteberegningOpen(!isSkatteberegningOpen);

  const gaaTil = (side: string) => {
    if (side === EKTEFELLESIDE && side !== gjeldendeSide) {
      dispatchTilEktefelle();
      analytics.knappKlikk(
        'Gå til skattemelding for ektefelle',
        'Skattemelding',
      );
    } else if (side === SKATTEMELDINGSIDE && side !== gjeldendeSide) {
      dispatchTilSkattemelding();
      analytics.knappKlikk(
        'Gå til skattemeldingsiden',
        'Skattemelding ektefelle',
      );
    }
  };

  return erGift && !beregnKplModus ? (
    <>
      <InfoboksBeregnSkattenDin />
      <EstimertSkattTopp
        skatteberegningRef={skatteberegningRef}
        setIsSkatteberegningOpen={setIsSkatteberegningOpen}
      />
      <Tabs
        onLinkClick={(item) => {
          gaaTil(item!.props!.itemKey!);
        }}
      >
        <TabItem
          headerText={intl.formatMessage({
            id: 'ledetekst.navigering.tilSkattemelding',
          })}
          itemKey={SKATTEMELDINGSIDE}
        >
          <Skattemeldingside
            skatteberegningRef={skatteberegningRef}
            isSkatteberegningOpen={isSkatteberegningOpen}
            setIsSkatteberegningOpen={setIsSkatteberegningOpen}
            toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
          />
        </TabItem>
        <TabItem
          headerText={intl.formatMessage({
            id: 'ledetekst.navigering.tilSkattemeldingEktefelle',
          })}
          itemKey={EKTEFELLESIDE}
        >
          <Skattemeldingside
            skatteberegningRef={skatteberegningRef}
            isSkatteberegningOpen={isSkatteberegningOpen}
            setIsSkatteberegningOpen={setIsSkatteberegningOpen}
            toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
          />
        </TabItem>
      </Tabs>
    </>
  ) : (
    <>
      {!beregnKplModus && (
        <>
          <InfoboksBeregnSkattenDin />
          <EstimertSkattTopp
            skatteberegningRef={skatteberegningRef}
            setIsSkatteberegningOpen={setIsSkatteberegningOpen}
          />
        </>
      )}
      <Skattemeldingside
        skatteberegningRef={skatteberegningRef}
        isSkatteberegningOpen={isSkatteberegningOpen}
        setIsSkatteberegningOpen={setIsSkatteberegningOpen}
        toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
      />
    </>
  );
};

const InfoboksBeregnSkattenDin = () => {
  const intl = useIntl();

  return (
    <Panel
      title={intl.formatMessage({ id: 'header.infoboks.beregnSkattenDin' })}
      color={'denim'}
      variant={'filled'}
      renderIcon={() => (
        <div className={style.beregnSkattenDinIkon}>
          <Icon svgPath={CalculatorSVGpath} size={'extraLarge'} />
        </div>
      )}
      spacing={'m'}
      className={style.beregnSkattenDinInfoboks}
    >
      <FormattedHTMLMessageWithFallbackToUgly
        id={'ledetekst.infoboks.beregnSkattenDin.innhold'}
      />
    </Panel>
  );
};

export const EstimertSkattTopp = ({
  skatteberegningRef,
  setIsSkatteberegningOpen,
  isScrolledPast,
  erSticky,
}: {
  skatteberegningRef: MutableRefObject<HTMLDivElement | null>;
  setIsSkatteberegningOpen: (isOpen: boolean) => void;
  isScrolledPast?: boolean;
  erSticky?: boolean;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const intl = useIntl();
  const { inntektsaar } = useInntektsaar();
  const beregnetSkattData = useBeregnetSkattData();
  const beregnetSkatt = beregnetSkattData?.beregnetSkatt | 0;

  if (!isScrolledPast && erSticky) {
    return null;
  }

  const visDetaljer = () => {
    setIsSkatteberegningOpen(true);
    skatteberegningRef.current?.focus();
    scrollToPosition(getPos(skatteberegningRef.current));
  };

  return (
    <Panel
      color={'forest'}
      spacing={erSticky ? 's' : 'm'}
      padding={erSticky ? 's' : undefined}
      className={classnames(style.estimertSkattInfoboks, {
        [style.estimertSkattInfoboksSticky]: erSticky,
      })}
    >
      <div className={style.panelWrapper}>
        <div>
          <div className={style.headerWrapper}>
            <Heading
              id={'headerLabel'}
              as={'h3'}
              level={3}
              className={style.headerLabel}
            >
              {intl.formatMessage(
                {
                  id: `header.infoboks.beregnetSkatt.topp${
                    erSticky ? '.sticky' : ''
                  }`,
                },
                {
                  aar: inntektsaar,
                  beloep: `${formaterTall(beregnetSkatt, intl)}`,
                },
              )}
            </Heading>
            <Popover
              isOpen={isPopoverOpen}
              onClose={() => setIsPopoverOpen(false)}
            >
              <Popover.Trigger
                ariaDescribedby={'headerLabel'}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
              <Popover.Content>
                {intl.formatMessage({ id: 'hjelpetekst.beregnetSkatt' })}
              </Popover.Content>
            </Popover>
          </div>
          {!erSticky && (
            <div className={style.beloepTekst}>
              {`${formaterTall(beregnetSkatt, intl)} kr`}
            </div>
          )}
        </div>
        <div className={style.visDetaljerButton}>
          <Button
            variant={'secondary'}
            svgPath={ArrowDownSVGpath}
            onClick={() => visDetaljer()}
          >
            {intl.formatMessage({ id: 'knapp.vis.detaljer' })}
          </Button>
        </div>
      </div>
    </Panel>
  );
};
