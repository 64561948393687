import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import LabelWithCallout from '@skatteetaten/frontend-components/LabelWithCallout';
import { Panel } from '@skatteetaten/ds-content';
import { Spinner } from '@skatteetaten/ds-progress';
import { ArrowForwardSVGpath } from '@skatteetaten/ds-icons';
import { Heading } from '@skatteetaten/ds-typography';
import { Link } from '../DS/Link';
import style from './kildeskattPaaLoenn.module.scss';
import oversiktStyle from '../oversikt/Oversikt.module.scss';
import kortgruppeStyle from '../tema/KortgruppeHeader.module.scss';

import { useInntektsaar } from '../../../hooks/useInntektsaar';
import {
  getBeregnetSkatt,
  getBeregningPaagaar,
  getLocale,
} from '../../../reducers';
import { useBeregnKildeskattPaaLoenn } from './useKildeskattPaaLoenn';
import { OppsummeringHeader } from '../oversikt/OppsummeringOgSkatteberegning';
import { formaterTall } from '../util/FormatertTall';

export const OppsummeringOgBeregningKildeskattPaaLoenn = () => {
  const intl = useIntl();
  const { inntektsaar } = useInntektsaar();

  const { beregnetSkatt } = useSelector(getBeregnetSkatt) || {};
  const { beregnetSkattKpl } = useBeregnKildeskattPaaLoenn();
  const beregningPaagaar = useSelector(getBeregningPaagaar);

  const hjelpetekst = (key: string, values: any = {}) =>
    intl.formatMessage(
      {
        id: `hjelpetekst.oppsummering.kpl.beregnetskatt.${key}`,
      },
      values,
    );
  const ledetekst = (key: string) =>
    intl.formatMessage({
      id: `ledetekst.oppsummering.kpl.beregnetskatt.${key}`,
    });

  return (
    <>
      <div className={oversiktStyle.oppsummeringOgBeregning}>
        <OppsummeringHeader
          titleId={'header.infoboks.beregnetSkatt.topp'}
          values={{ aar: inntektsaar }}
        />
        <div className={style.oppsummeringOgBeregningForKildeskattPaaLoenn}>
          <BeregnetSkattPanel
            labelTekst={ledetekst('kpl')}
            helpTekst={hjelpetekst('kpl')}
            beloepElement={`${formaterTall(beregnetSkattKpl, intl)} kr`}
            groennBorder={beregnetSkattKpl < beregnetSkatt}
          />
          <BeregnetSkattPanel
            labelTekst={ledetekst('ordinaer')}
            helpTekst={hjelpetekst('ordinaer')}
            beloepElement={
              beregningPaagaar ? (
                <Spinner>Beregner...</Spinner>
              ) : (
                `${formaterTall(beregnetSkatt, intl)} kr`
              )
            }
            groennBorder={beregnetSkatt < beregnetSkattKpl}
          />
        </div>
      </div>
      <BeskrivelseAvKplVsOrdinaerSkattInfoboks />
    </>
  );
};

const BeregnetSkattPanel = ({
  labelTekst,
  helpTekst,
  beloepElement,
  groennBorder,
}: {
  labelTekst: string;
  helpTekst: string;
  beloepElement: string | React.ReactElement;
  groennBorder: boolean;
}) => {
  return (
    <Panel
      color="denim"
      variant="filled"
      padding="m"
      className={classNames(style.panel, {
        [style.panelGreenBorder]: groennBorder,
      })}
    >
      <div className={style.panelContent}>
        <span tabIndex={-1} role="heading" aria-level={3}>
          <LabelWithCallout
            label={labelTekst}
            help={helpTekst}
            customClassNames={{
              wrapper: style.headerWrapper,
              callout: kortgruppeStyle.calloutStyle,
              label: style.headerLabel,
            }}
          />
        </span>
        <div className={style.beloep}>{beloepElement}</div>
      </div>
    </Panel>
  );
};

const BeskrivelseAvKplVsOrdinaerSkattInfoboks = () => {
  const intl = useIntl();
  const locale = useSelector(getLocale);

  const payeUrl =
    locale === 'nb'
      ? 'https://www.skatteetaten.no/person/utenlandsk/skal-du-arbeide-i-norge/skattekort/kildeskatt-pa-lonn/'
      : locale === 'nn'
      ? 'https://www.skatteetaten.no/nn/person/utanlandsk/skal-du-arbeide-i-norge/skattekort/kjeldeskatt-pa-lonn-for-utanlandske-arbeidstakare/'
      : 'https://www.skatteetaten.no/en/person/foreign/are-you-intending-to-work-in-norway/tax-deduction-cards/paye/';

  const header = (key: string) =>
    intl.formatMessage({
      id: `header.oppsummering.kpl.beskrivelseAvKpl.${key}`,
    });
  const ledetekst = (key: string, values: any = {}) =>
    intl.formatMessage(
      { id: `ledetekst.oppsummering.kpl.beskrivelseAvKpl.${key}` },
      values,
    );

  return (
    <Panel
      color="denim"
      variant="filled"
      padding="m"
      className={style.oppsummeringInfoboksForKildeskattPaaLoenn}
    >
      <div className={style.infoboksContainer}>
        <Heading level={3} as={'h3'}>
          {header('title')}
        </Heading>
        <div className={style.infoboksContent}>
          <div className={style.infoboksContentItem}>
            <h4>{header('kpl')}</h4>
            <p>{ledetekst('kpl')}</p>
            <p>
              <Link
                href={payeUrl}
                isExternal={true}
                svgPath={ArrowForwardSVGpath}
              >
                {ledetekst('kpl.lenkeTekst')}
              </Link>
            </p>
          </div>
          <div className={style.infoboksContentItem}>
            <h4>{header('ordinaerSkatt')}</h4>
            <p>{ledetekst('ordinaerSkatt')}</p>
          </div>
        </div>
      </div>
    </Panel>
  );
};
